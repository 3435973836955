module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"defaultLayouts":{"mdxPages":"/home/runner/work/design-system-website/design-system-website/src/templates/layout-mdx.js"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"The Economist","short_name":"Economist","start_url":"/","background_color":"#E3120B","theme_color":"#E3120B","display":"standalone","icon":"static/images/touch-icon-512x512.png","icons":[{"src":"images/touch-icon-120x120.png","sizes":"120x120","type":"image/png"},{"src":"images/touch-icon-152x152.png","sizes":"152x152","type":"image/png"},{"src":"images/touch-icon-167x167.png","sizes":"167x167","type":"image/png"},{"src":"images/touch-icon-192x192.png","sizes":"192x192","type":"image/png"},{"src":"images/touch-icon-512x512.png","sizes":"512x512","type":"image/png"}],"cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"92eaf29cc28ba99bd6b5d31464bea3ca"},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://design-system.economist.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-168651299-1","head":true},
    }]
