import { SocialIconFacebook, SocialIconInstagram, SocialIconLinkedIn, SocialIconRss, SocialIconTwitter, SocialIconWhatsApp, SocialIconYoutube } from '@economist/design-system/dist/umd/common';
import * as React from 'react';
export default {
  SocialIconFacebook,
  SocialIconInstagram,
  SocialIconLinkedIn,
  SocialIconRss,
  SocialIconTwitter,
  SocialIconWhatsApp,
  SocialIconYoutube,
  React
};